import { forwardRef } from "react";
import AddBoxIcon from "@mui/icons-material/AddBox";
import Check from "@mui/icons-material/Check";
import Clear from "@mui/icons-material/Clear";
import DeleteOutline from "@mui/icons-material/DeleteOutline";
import ChevronRight from "@mui/icons-material/ChevronRight";
import Edit from "@mui/icons-material/Edit";
import SaveAlt from "@mui/icons-material/SaveAlt";
import FilterList from "@mui/icons-material/FilterList";
import FirstPage from "@mui/icons-material/FirstPage";
import LastPage from "@mui/icons-material/LastPage";
import ChevronLeft from "@mui/icons-material/ChevronLeft";
import Search from "@mui/icons-material/Search";
import ArrowDownward from "@mui/icons-material/ArrowDownward";
import Remove from "@mui/icons-material/Remove";
import ViewColumn from "@mui/icons-material/ViewColumn";
import { isEqual } from "common/helpers";
import { CRITIC_COLORS_RANGE } from "theme/palette";

const getURLFirebase = () => {
  switch (process.env.NODE_ENV) {
  case "production":
    return "https://us-central1-nala-system.cloudfunctions.net";
  case "development":
    return "http://localhost:5001/nala-system/us-central1";
  case "test":
    return "http://localhost:5001/nala-system/us-central1";
  default:
    return "https://us-central1-nala-system.cloudfunctions.net";
  }
};

const getURLRails = () => {
  switch (process.env.REACT_APP_ENV) {
  case "production":
    return "https://api.nalarocks.com";
  case "development":
    return "https://staging.api.nalarocks.com";
  case "test":
    return "http://localhost:3001";
  default:
    return "https://staging.api.nalarocks.com";
  }
};

export const YOUTUBE_URLS = {
  main: "https://www.youtube.com/oembed?url=http://www.youtube.com/watch?v=",
  format: "&format=json",
  external: "https://youtu.be/",
};

export const CURRENT_DOMAIN = `${process.env.REACT_APP_PROTOCOL}://${process.env.REACT_APP_AUTH_DOMAIN}`;
export const FUNCTIONS_API_URL_BASE = getURLFirebase();
export const API_URL_BASE_RAILS = getURLRails();
export const API_V1 = "/api/v1";
export const API_URL_RAILS_V1 = API_URL_BASE_RAILS + API_V1;
export const CRYPTO_KEY = `${process.env.REACT_APP_KEY}`;
export const GA_TRACKING_ID = `${process.env.REACT_APP_GA_TRACKING_ID}`;
export const GOOGLE_TRANSLATE_URL = "https://translate.google.com/translate";
export const HJID = `${process.env.REACT_APP_HJID}`;
export const CLARITY_ID = `${process.env.REACT_APP_CLARITY_ID}`;
export const MANDRILL_KEY = `${process.env.REACT_APP_MANDRILL_KEY}`;
export const POSTHOG_KEY = `${process.env.REACT_APP_PUBLIC_POSTHOG_KEY}`;
export const POSTHOG_HOST = `${process.env.REACT_APP_PUBLIC_POSTHOG_HOST}`;

export const SLACK_CLIENT_ID = `${process.env.REACT_APP_SLACK_CLIENT_ID}`;

export const headersWithEmail = (email) => ({
  "x-email": email,
  "Access-Control-Allow-Origin": "*",
  "Content-Type": "application/json",
});

export const ROLES = JSON.parse(process.env.REACT_APP_ROLES);

export const tableIcons = {
  Add: forwardRef((props, ref) => <AddBoxIcon { ...props } ref={ ref } />),
  Check: forwardRef((props, ref) => <Check { ...props } ref={ ref } />),
  Clear: forwardRef((props, ref) => <Clear { ...props } ref={ ref } />),
  Delete: forwardRef((props, ref) => <DeleteOutline { ...props } ref={ ref } />),
  DetailPanel: forwardRef((props, ref) => (
    <ChevronRight { ...props } ref={ ref } />
  )),
  Edit: forwardRef((props, ref) => <Edit { ...props } ref={ ref } />),
  Export: forwardRef((props, ref) => <SaveAlt { ...props } ref={ ref } />),
  Filter: forwardRef((props, ref) => <FilterList { ...props } ref={ ref } />),
  FirstPage: forwardRef((props, ref) => <FirstPage { ...props } ref={ ref } />),
  LastPage: forwardRef((props, ref) => <LastPage { ...props } ref={ ref } />),
  NextPage: forwardRef((props, ref) => <ChevronRight { ...props } ref={ ref } />),
  PreviousPage: forwardRef((props, ref) => (
    <ChevronLeft { ...props } ref={ ref } />
  )),
  ResetSearch: forwardRef((props, ref) => <Clear { ...props } ref={ ref } />),
  Search: forwardRef((props, ref) => <Search { ...props } ref={ ref } />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward { ...props } ref={ ref } />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove { ...props } ref={ ref } />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn { ...props } ref={ ref } />),
};

export const BASE_URL_HR_ANALYTICS = "https://app.powerbi.com/view?r=";

export const IFRAME_URL_TRAINING_DASHBOARD = {
  nala_demo:
    "",
};

export const HC_PLANNING_CALCULATOR_IFRAME = "";

export const FIRST_PAGE = "first_page";
export const PREV_PAGE = "prev_page";
export const NEXT_PAGE = "next_page";
export const LAST_PAGE = "last_page";

export const ASC = "asc"; // for ascending
export const DESC = "desc"; // for descending

export const ORDER_BY_NAME = "name";
export const ORDER_BY_UNITY = "unity";

export const PERFORMANCE_MAX = 1.2;

export const MIN_PERCENTAGE_TO_RECOMMEND = 80;

export const DAYS_TO_FINISH_ONBOARDING = 100;

export const MAX_EMPLOYEES_QUERY = 100;

export const RATING = {
  initial: 0,
  stars: 5,
  total_min: 100,
  total_max: 120,
  precision: 0.1,
};

export const INFO = "info";
export const SUCCESS = "success";
export const WARNING = "warning";
export const ERROR = "error";
export const CRITIC = "critic";

// FIXME: remove when receiving scale
export const LEADER_CLASSIFICATION_RANK = {
  EXEMPLARY_LEADER: [0.9, 1],
  GOOD_LEADER: [0.75, 0.89],
  LEADER_IN_DEVELOPMENT: [0.6, 0.74],
  BOX: [0, 0.59],
};

export const HEAT_MAP_LABEL_RANGE = {
  critic: "0% - 49%",
  low: "50% - 69%",
  medium: "70% - 89%",
  normal: "90% - 99%",
  excellent: "100% - 109%",
  great: "110% - 120%",
};

export const HEAT_MAP_LABEL_RANGE_LEADER = {
  critic: "0% - 59%",
  low: "60% - 69%",
  medium: "70% - 79%",
  normal: "80% - 89%",
  excellent: "90% - 94%",
  great: "95% - 100%",
};

export const HEAT_MAP_VALUE_RANGE = {
  critic: {
    min: 0,
    max: 50, // max used on inRange lodash function wich does not include the max
  },
  low: {
    min: 50,
    max: 70,
  },
  medium: {
    min: 70,
    max: 90,
  },
  normal: {
    min: 90,
    max: 100,
  },
  excellent: {
    min: 100,
    max: 110,
  },
  great: {
    min: 110,
  },
};

export const HEAT_MAP_VALUE_RANGE_LEADER = {
  critic: {
    min: 0,
    max: 60, // max used on inRange lodash function wich does not include the max
  },
  low: {
    min: 60,
    max: 70,
  },
  medium: {
    min: 70,
    max: 80,
  },
  normal: {
    min: 80,
    max: 90,
  },
  excellent: {
    min: 90,
    max: 95,
  },
  great: {
    min: 95,
  },
};

export const RISK_RESULT_VALUES = {
  critic: 100,
  high: 80,
  medium: 60,
  low: 40,
  none: 20,
  zero: 0,
  index: {
    zero: 0,
    one: 1,
    two: 2,
    three: 3,
    four: 4,
  },
  additionalPadding: 10,
};

export const GRAPHIC_LABEL_OFFSET = 15;
export const GRAPHIC_TOP_PERCENTAGE = 100;

export const MAX_PERCENTAGE = 100;
export const MAX_PER_PAGE = 15;
export const ROWS_PER_PAGE = [15, 30, 45];
export const ROWS_RANGE_PER_PAGE = [5, 15, 30, 45, 60];
export const ROWS_RANGE_PER_PAGE_LIGHT = [3, 5, 15];
export const TIMEOUT_TRANSITION = 350;
export const TIMEOUT_NOTIFICATIONS = 1500;
export const AUTO_HIDE_DURATION = 8000;
export const ALERT_ANCHOR_ORIGIN = {
  vertical: "top",
  horizontal: "right",
};

export const STATE = {
  SELECTED: "selected",
  FILL_DATA: "fill_data",
  DATA_FILLED: "data_filled",
  REQUIRE_MORE_DATA: "require_more_data",
  DATA_REQUIRED: "data_required",
  HIRE: "hire",
  HIRED: "hired",
  DESIST: "desist",
  DESISTED: "desisted",
  //  FIXME: change all this constants above to lowercase
  turnover: "Turnover",
  start: "start",
  answered: "answered",
  answer: "answer",
  draft: "draft",
  drafted: "drafted",
  program: "program",
  programmed: "programmed",
  running: "running",
  launch: "launch",
  finished: "finished",
  finish: "finish",
};

export const MARITAL_STATUS = {
  SINGLE: "common:common.marital_status.single",
  MARRIED: "common:common.marital_status.married",
  FREE_UNION: "common:common.marital_status.free_union",
  SEPARATED: "common:common.marital_status.separated",
  DIVORCED: "common:common.marital_status.divorced",
  WIDOWER: "common:common.marital_status.widower",
};

export const GENDER_STATUS = {
  female: "common:common.gender_status.female",
  male: "common:common.gender_status.male",
  nonBinary: "common:common.gender_status.nonBinary",
  preferNotToSay: "common:common.gender_status.preferNotToSay",
  other: "common:common.gender_status.other",
};

export const ROLES_NAMES = {
  collaborator: "common:common.roles.collaborator",
  manager: "common:common.roles.manager",
  admin: "common:common.roles.admin",
  talent_manager: "common:common.roles.talent_manager",
  vacation_admin: "common:common.roles.vacation_admin",
  onboarding_admin: "common:common.roles.onboarding_admin",
  successions_admin: "common:common.roles.successions_admin",
};

export const MOVEMENT_TYPES = {
  LateralMovement: "account:movementsHistory.LateralMovement",
  Promotion: "account:movementsHistory.Promotion",
  SalaryRaise: "account:movementsHistory.SalaryRaise",
};

export const DATA_URL_KEY = "dataURLKey"; // for UploadImage component

export const NINEBOX = {
  generalViewBox: "0 0 100 100",
  generalPaddingViewBox: "-4 -4 108 105",
  footerViewBox: "0 0 100 3",
  iconViewBox: "0 0 10 10",
  iconX: 24,
  iconY: 2.5,
  iconWidth: 10,
  maxBox: 33.333333333333336,
  xMaxBox: 66.66666666666667,
  zero: 0.0,
  box: {
    a: {
      x: 2,
      y: 3.5,
    },
    b: {
      x: 35.5,
    },
    c: {
      x: 69,
    },
    d: {
      y: 37,
    },
    g: {
      y: 70.5,
    },
  },
  footerBox: {
    xa: 15,
    xb: 45,
    xc: 78,
  },
  size: 1,
  total: 100,
  totalPercentage: "100%",
  quadrantBorder: 1,
  quadrantData: {
    x: 0,
    y: 1,
    width: 2,
    fill: 3,
    name: 4,
    xText: 5,
    yText: 6,
    description: 7,
    xMin: 8,
    xMax: 9,
    yMin: 10,
    yMax: 11,
    xPercentage: 12,
    yPercentage: 13,
    quadrant: 14,
    scale: 15,
    quadrantNumber: 16,
  },
  quadrantIndex: {
    zero: 0,
    first: 1,
    second: 2,
    third: 3,
    fourth: 4,
    fifth: 5,
    sixth: 6,
    seventh: 7,
    eighth: 8,
    all: 9,
  },
  footerData: {
    x: 0,
    xText: 1,
  },
  boxesGroup: {
    narrow: 0,
    balance: 1,
    broad: 2,
  },
  boxRange: {
    xBox: {
      low: {
        min: 0,
        max: 54,
      },
      medium: {
        min: 55,
        max: 84,
      },
      high: {
        min: 85,
        max: 100,
      },
    },
    yBox: {
      low: {
        min: 0,
        max: 75,
      },
      medium: {
        min: 75.1,
        max: 87.5,
      },
      high: {
        min: 87.51,
        max: 100,
      },
    },
  },
  boxPercent: 1,
  tooltip: {
    rect: {
      x: -18,
      y: -2.8,
      width: 3,
      height: 3.5,
      rx: 0.5,
      ry: 0.5,
    },
    text: {
      x: -16,
      y: -0.5,
    },
  },
  focus: {
    position: 3.5,
    width: 6.5,
  },
  group: {
    max: 9,
    min: 2,
    initial: 1,
    zero: 0,
  },
  results: {
    potential: "potentialResult",
    performance: "performanceResult",
  },
  radius: {
    max: 3,
  },
  image: {
    id: "person",
    aspectRatio: "none",
  },
  round: {
    min: 0,
    max: 2,
  },
  resultScale: {
    top: "top",
  },
};

export const XLSX_EXTENSION = "xlsx";
export const FILE_TYPE_EXCEL = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";

export const ERROR_VALUE = 500;

export const AUTH_NETWORK_FAILED_FIREBASE = "auth/network-request-failed";
export const CLOSED_POPUP_FAILED_FIREBASE = "auth/cancelled-popup-request";
export const CLOSED_POPUP_USER_FIREBASE = "auth/popup-closed-by-user";

export const PROFILE_INDEX_TABS = {
  profile: 0,
  personalData: 1,
  salary: 2,
  performance: 3,
  leadership: 4,
  induction: 5,
  potential: 6,
  documents: 7,
  feedback: 8,
  lossRisk: 9,
};

export const COLLABORATOR_INDEX_TABS = {
  active: 0,
  inactive: 1,
};

export const REMOVE_OPTION = "remove-option";
export const CLEAR = "clear";

export const SKELETONS_NUMBER = {
  FOUR: 4,
  FIVE: 5,
  SIX: 6,
  EIGHT: 8,
  NINE: 9,
  TWO: 2,
  ONE: 1,
  THREE: 3,
};

export const SKELETON_VARIANT = {
  rectangular: "rectangular",
  text: "text",
};

export const POSITION = {
  static: "static",
};

export const TIMEOUT_REDIRECT = 2000;
export const TIMEOUT_DISPATCH = 1000;
export const TIMEOUT_EMPTY_PAGE = 200;

export const COMPANY_SLUGS = JSON.parse(process.env.REACT_APP_COMPANY_SLUGS);

export const COMPANIES_WITHOUT_PERCENTAGE_PROFILE_PERFORMANCE = process.env.REACT_APP_COMPANIES_WITHOUT_PERCENTAGE_PROFILE_PERFORMANCE.split(", ");

export const COMPANIES_WITHOUT_PERSONALITY_TEST = process.env.REACT_APP_COMPANIES_WITHOUT_PERSONALITY_TEST.split(", ");

export const ORG_UNITS = {
  area: "Area",
  division: "Division",
  subarea: "Subarea",
};

export const ENTITY_OPTION = {
  country: "country",
  city: "city",
};

export const OBJECT_KEYS = {
  // A
  actions: "goals_actions",
  area: "area",
  averageCommissions: "average_commissions",
  archive: "archieve",
  additionalFields: "additional_fields",
  audience: "audience",
  audienceId: "audience_id",
  // B
  birthDate: "birth_date",
  birthdate: "birthdate",
  benefit: "benefit",
  // C
  category: "category",
  cityName: "city_name",
  countryName: "country_name",
  completedValue: "completed_value",
  completionPercentage: "completion_percentage",
  compliance: "compliance",
  comment: "comment",
  comments: "comments",
  createdAt: "created_at",
  collaboratorsCount: "collaborators_count",
  currency: "currency",
  company: "company",
  companyEmail: "company_email",
  country: "country",
  country_id: "country_id",
  city: "city",
  costCenter: "cost_center",
  children: "children",
  code: "code",
  collaborator: "collaborator",
  collaboratorsIds: "collaborators_ids",
  contractTypes: "contract_types",
  connectedUsers: "connectedUsers",
  completePath: "complete_path",
  calibrationActivated: "calibration_activated",
  // D
  date: "date",
  dates: "dates",
  description: "description",
  division: "division",
  directory: "directory",
  dayControl: "day_control",
  dentalInsurance: "dental_insurance",
  downloadImage: "download-image",
  documentFile: "document-file",
  documentType: "document-type",
  days: "days",
  day: "day",
  // E
  employeeFullName: "employee_full_name",
  employee: "employee",
  employeeId: "employee_id",
  evaluationType: "evaluation_type",
  evaluation: "evaluation",
  end_date: "end_date",
  errors: "errors",
  employmentRelationshipOnly: "employment_relationship",
  employmentRelationship: "employment_relationship.name",
  employeesCount: "active_collaborators_count",
  employeesIds: "employees_ids",
  // F
  filename: "filename",
  fileName: "file_name",
  finishContract: "finishContract",
  fullname: "full_name",
  frequency: "frequency",
  finishDate: "finish_date",
  firstDate: "first_date",
  followUpProcessId: "follow_up_process_id",
  // G
  grossSalary: "gross_salary",
  goalRuleId: "goal_rule_id",
  gender: "gender",
  // H
  high: "high",
  healthInsurance: "health_insurance",
  human_resources: "human_resources",
  hierarchy_level: "hierarchy_level",
  // I
  id: "id",
  infoType: "info-type",
  isActive: "is_active",
  isMain: "is_main",
  integrations: "integrations",
  // K
  keepDoing: "keep_doing",
  // L
  lateralMovement: "LateralMovement",
  label: "label",
  level: "level",
  legalEntityCode: "legal_entity_code",
  legalEntity: "legalEntity",
  lifeInsurance: "life_insurance",
  listings: "listings",
  lossRisk: "lossRisk",
  low: "low",
  lastUpdated: "update_at",
  // M
  maritalStatus: "marital_status",
  managerName: "manager_name",
  metric: "metric",
  medium: "medium",
  minWorkedDays: "min_worked_days",
  month: "month",
  managerId: "manager_id",
  mealTicket: "meal_allowance",
  multicompany: "multicompany",
  // N
  name: "name",
  nameEs: "name_es",
  nameEn: "name_en",
  namePt: "name_pt",
  nameWithCode: "name_with_code",
  null: "null",
  new: "new",
  newHires: "new_hires",
  notifications: "notifications",
  // O
  organizationUnit: "organization_unit",
  organizationUnits: "organization_units",
  otherBenefits: "other_benefits",
  otherGender: "other_gender",
  order: "order",
  // P
  participation: "participation",
  promotion: "Promotion",
  profileImgUrl: "profile_img_url",
  positionName: "position_name",
  payrollItemId: "payroll_item_id",
  performance: "performance",
  principalActions: "actions",
  position: "position",
  pairs: "pairs",
  positionId: "position_id",
  personalEmail: "personal_email",
  process: "process",
  processType: "process_type",
  phoneNumber: "phone_number",
  performanceScore: "performance_score",
  // R
  rolesName: "roles_name",
  recommendation: "recomendation",
  result: "result",
  reasonOfTermination: "reason_of_termination",
  reminderDays: "reminder_days",
  resultScale: "result_scale",
  resultScaleId: "result_scale_id",
  // S
  satisfaction: "satisfaction",
  salaryRaise: "SalaryRaise",
  score: "score",
  scope: "scope",
  state: "state",
  start_date: "start_date",
  startingDate: "starting_date",
  secondDate: "second_date",
  subarea: "subarea",
  salary: "salary",
  surveyProcess: "survey_process",
  stopDoing: "stop_doing",
  slackConfig: "slack_config",
  search: "search",
  selectTemplate: "select_template",
  surveyProcessTemplateId: "survey_process_template_id",
  successionPlanId: "succession_plan_id",
  // T
  template: "template",
  time: "time",
  typeOfContractId: "type_of_contract_id",
  total: "total",
  totalGoals: "total_goals",
  title: "title",
  type: "type",
  terminationDate: "termination_date",
  typeOfContract: "type_of_contract",
  top: "top",
  topTalent: "top_talent",
  turnoverType: "turnover_type",
  turnoverReason: "turnover_reason",
  turnoverComments: "turnover_comments",
  turnoverReasonId: "turnover_reason_id",
  // U
  undefined: "undefined",
  userIdCompany: "user_id_company",
  userId: "user_id",
  unit: "unit",
  unarchive: "unarchieve",
  url: "url",
  // V
  value: "value",
  // W
  weight: "weight",
  zoomIn: "zoom-in",
  zoomOut: "zoom-out",
};

export const BUTTON_STYLE_TYPES = {
  SUBMIT: "submit",
  CANCEL: "cancel",
  BACK: "back",
  OUTLINED: "outlined",
  DISABLED: "disabled",
  CONTAINED: "contained",
  BUTTON: "button",
};

export const EVALUATION_CLASSIFICATION_RANK = {
  SUPER_STAR: [85, 100],
  STAR: [70, 84],
  CONSISTENT: [50, 69],
  IN_DEVELOPMENT: [40, 49],
  NOT_CONSISTENT: [0, 39],
};

export const CLASSIFICATION_RANK = {
  SUPER_STAR: "superStar",
  STAR: "star",
  CONSISTENT: "consistent",
  IN_DEVELOPMENT: "inDevelopment",
  NOT_CONSISTENT: "notConsistent",
};

export const CLASSIFICATION_RANK_WITH_DESCRIPTION = {
  "Super Estrella": CLASSIFICATION_RANK.SUPER_STAR,
  Estrella: CLASSIFICATION_RANK.STAR,
  Consistente: CLASSIFICATION_RANK.CONSISTENT,
  "En desarrollo": CLASSIFICATION_RANK.IN_DEVELOPMENT,
  "Poco consistente": CLASSIFICATION_RANK.NOT_CONSISTENT,
  // Scale for La Haus
  "Top Talent": CLASSIFICATION_RANK.SUPER_STAR,
  Sobresaliente: CLASSIFICATION_RANK.STAR,
  Inconsistente: CLASSIFICATION_RANK.NOT_CONSISTENT,
};

export const VISIBILITY = {
  COMPACT: "Compact",
  WIDE: "Wide",
};

export const PERFORMANCE_MAX_PERCENTAGE = 100;
export const FORMAT_TEXT = /([a-z])([A-Z])/g;
export const MIN_STAR_RATING = 0;

export const TYPES = {
  string: "string",
  object: "object",
  number: "number",
  email: "email",
  date: "date",
  text: "text",
  hidden: "hidden",
  checkbox: "checkbox",
};

export const NUMBER_OF_EVALUATIONS = 4;

export const RADIO_OPTIONS = {
  voluntary: "voluntary",
  involuntary: "involuntary",
};

export const GRID_DIVIDERS = 10;
export const COLORS_NAME = {
  light: "light",
  purple: "purple",
  primary: "primary",
  black: "black",
};

export const DATE = "date";

export const LANGUAGES = {
  en: "en",
  es: "es",
  pt: "pt",
};

export const LANGUAGES_NAMES = {
  spanish: "Español",
  english: "English",
  portuguese: "Português",
};

export const TYPES_RESULTS = {
  deep: "Profundo",
  balanced: "Balanceado",
  large: "Amplio",
  skills: {
    mental: "po_mental_agility",
    interpersonal: "po_people_agility",
    change: "po_change_agility",
    results: "po_results_agility",
    awareness: "po_self_awareness",
  },
  skillKeys: {
    mental: "mental",
    interpersonal: "interpersonal",
    change: "change",
    results: "results",
    awareness: "awareness",
  },
  skillProp: {
    talented: "talented",
    develop: "developed",
    toDevelop: "toDevelop",
  },
  en: {
    high: "Broad",
    moderate: "Balance",
    limited: "Narrow",
  },
  pt: { // TODO: This should be removed when ticket #183279601 is resolved.
    high: "Amplo",
    moderate: "Equilibrado",
    deep: "Profundo",
  },
};

export const TYPES_TALENTS = {
  PEOPLE_AGILITY: {
    name: TYPES_RESULTS.skills.interpersonal,
    id: "people_agility",
  },
  MENTAL_AGILITY: { name: TYPES_RESULTS.skills.mental, id: "mental_agility" },
  CHANGE_AGILITY: {
    name: TYPES_RESULTS.skills.change,
    id: "change_agility",
  },
  RESULTS_AGILITY: {
    name: TYPES_RESULTS.skills.results,
    id: "results_agility",
  },
  SELF_AWARENESS: {
    name: TYPES_RESULTS.skills.awareness,
    id: "self_awareness",
  },
};

export const DASHBOARD_REDUCER = {
  performance: "performance",
  potential: "potential",
};

export const MIN_VALUE = 0; // DEPRECATED

export const PATTERN_OBJECT_BOUNDING = "objectBoundingBox";

export const EMAIL_LIST = process.env.REACT_APP_EMAIL_LIST.split(", ");

export const SCROLL_BEHAVIOR = {
  smooth: "smooth",
};

export const BORDER_ACCORDION_TYPE = {
  rounded: "rounded",
  square: "square",
};

export const WEEK_DAYS = [
  "sunday",
  "monday",
  "tuesday",
  "wednesday",
  "thursday",
  "friday",
  "saturday",
];

export const MONTHS = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

export const TOTAL_MONTHS = 12;

export const CARD_TYPE = {
  evaluation: "evaluation",
  badge: "badge",
};

export const PERSONALITY_EVALUATION = {
  energizes: 0,
  drains: 1,
};

export const EXCEL_FILES_NAME = {
  potentialNineBox: "potential-nine-box",
  leadersReview: "leaders-review",
  performanceReview: "performance-review-collaborators",
  potentialCollaborators: "potential-collaborators",
};

export const FORMAT_EMAIL = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
export const BULK_UPLOAD_FORMAT = /(.xlsx|.csv)$/i;
export const ACCEPTED_FORMATS = ".xlsx, .csv";

export const TYPE_BULK_UPLOAD = {
  onboarding: "onboarding",
  collaborators: "collaborators",
};

export const BULK_UPLOAD = {
  row_number: "row_number",
  xlsx: {
    type: {
      binary: "binary",
      array: "array",
    },
    col: 1,
    initialCounter: 2,
    firstData: 0,
    dataLimit: 10,
    ref: "!ref",
    maxCol: {
      collaborators: 31, // deprecated
      onboarding: 20,
    },
  },
  requiredSign: "*",
  state: {
    pending: "pending",
    successfully: "successfully",
    failed: "failed",
  },
  calculateTime: {
    min: 100,
  },
};

export const LINEAR_PROGRESS = {
  min: 0,
  max: 100,
  diff: 10,
  interval: 300,
  longInterval: 650,
};

export const PASSWORD_INPUT_LABEL_WIDTH = {
  large: 150,
  medium: 110,
  default: 70,
};
export const INPUT_TYPE_TOGGLE_PASSWORD = {
  password: "password",
  text: "text",
};

export const PASSWORD_VALIDATION_UTILS = {
  length: "length",
  confirmation: "confirmation",
  minLength: 8,
};

export const AUTH_METHODS = {
  password: "password",
  token: "token",
  google: "google",
};

export const ERROR_TYPE = {
  unauthorized: "unauthorized",
};

export const RECOVER_PASSWORD_UTILS = {
  tokenSearchPosition: 2,
  emailSearchPosition: 1,
  maxLength: 16,
  typeErrorMaxLength: "maxLength",
};

export const LOCAL_STORAGE_NAMES = {
  typeOfContract: "typeOfContract",
  countries: "countries",
  orgUnitsTypes: "orgUnitsTypes",
  orgUnits: "orgUnits",
  currencies: "currencies",
  positions: "positions",
  user: "user",
  username: "username",
  version: "version",
  logOutError: "logOutError",
  accessToken: "accessToken",
  client: "client",
  uid: "uid",
  isMulticompany: "isMulticompany",
  referrer: "referrer",
  userMenuOptions: "userMenuOptions",
  userPathOptions: "userPathOptions",
  newUpdate: "newUpdate",
};

export const RobotoFont = "Roboto";

export const ENGAGEMENT_DATA = {
  satisfactionThemeHeader: ["V", "O", "I", "C", "E", "-E"],
  searchItems: {
    name: "name",
    nps: "nps",
    participation: "participation",
    previousNps: "previous_nps_result",
    unsatisfiedTotal: "unsatisfied_total",
    neutralTotal: "neutral_total",
    satisfiedTotal: "satisfied_total",
    previousSatisfaction: "previous_satisfaction_result",
    result: "result",
    previousResult: "previous_result",
    id: "id",
  },
  range: {
    unsatisfied: { min: 0, max: 49 },
    unsatisfiedLight: { min: 49, max: 64 },
    neutralLight: { min: 64, max: 74 },
    neutral: { min: 74, max: 81 },
    satisfiedLight: { min: 81, max: 90 },
    satisfied: { min: 90, max: 101 },
  },
  criticalTopic: 0.64,
};

export const SIZE = {
  small: "small",
  medium: "medium",
  large: "large",
  xs: "xs",
  sm: "sm",
  md: "md",
  lg: "lg",
  xl: "xl",
};

export const TABLE_SIZE = {
  small: "small",
  medium: "medium",
};

export const TABLE_CELL_ALIGN = {
  left: "left",
};

export const ICON = {
  left: "left",
  right: "right",
  start: "start",
  end: "end",
  top: "top",
  bottom: "bottom",
};

export const VARIANT = {
  button: "button",
  paddingCheckbox: "checkbox",
  none: "none",
  block: "block",
  default: "default",
  dense: "dense",
  outlined: "outlined",
  determinate: "determinate",
  contained: "contained",
  bodyOne: "body1",
  bodyTwo: "body2",
  subtitleOne: "subtitle1",
  subtitleTwo: "subtitle2",
  h1: "h1",
  h2: "h2",
  h3: "h3",
  h4: "h4",
  h5: "h5",
  h6: "h6",
  normal: "normal",
  dot: "dot",
  circle: "circle",
  fullWidth: "fullWidth",
  scrollable: "scrollable",
  inherit: "inherit",
  caption: "caption",
  text: "text",
  standard: "standard",
  auto: "auto",
  square: "square",
  circular: "circular",
  rounded: "rounded",
};

export const CIRCULAR_PROGRESS = {
  large: 120,
  medium: 60,
  small: 20,
};

export const DECIMAL_SCALE_NUMBER_INPUT = 0;
export const DEFAULT_MIN_DECIMAL = 2;

export const FILTER_SEACH = {
  equal: "=",
};

export const FILTERS_KEYS = {
  country: "country",
  countries: "countries",
  city: "city",
  cities: "cities",
  unit: "unit",
  unities: "unities",
  manager: "manager",
  managers: "managers",
  collaborators: "collaborators",
  periods: "periods",
  state: "state",
  states: "states",
  collaboratorsStates: "collaboratorsStates",
  timeOffTypes: "timeOffTypes",
};

export const ONBOARDING_KEYS = {
  documentTypeId: "document_type_id",
  infoTypeId: "info_type_id",
  sectionItemId: "section_item_id",
};

export const KEYS_WITH_DECIMALS = {
  salary: "salary",
  average_commissions: "average_commissions",
  health_insurance: "health_insurance",
  meal_allowance: "meal_allowance",
  dental_insurance: "dental_insurance",
  life_insurance: "life_insurance",
  other_benefits: "other_benefits",
};

export const UNDEFINED = undefined;

export const FULLDATE_FORMATS = {
  slash: "DD/MM/YYYY",
  slashShort: "DD/MM/YY",
  dash: "DD-MM-YYYY",
  slash_calendar: "YYYY/MM/DD",
  slashTime: "DD/MM/YYYY hh:mm:ss",
  timeline: "yyyy-MM-DD",
};

export const FULLDATE_FORMATS_STANDARD = {
  slash: "dd/MM/yyyy",
  dash: "dd-MM-yyyy",
};

export const SORT_COMPARATOR = {
  one: 1,
  minusOne: -1,
  zero: 0,
};

export const GOALS_INDEX_TABS = {
  inProgress: 0,
  archived: 1,
};

export const DESCENDANT = "is_descendant";
export const EMPLOYEES_TO_REMOVE_NUMBER = 1;

export const COLLABORATORS_TABLE_ACTIONS = {
  view: "view",
  edit: "edit",
  promote: "promote",
  lateralMovement: "lateralMovement",
  salaryRaise: "salaryRaise",
};

export const MODE_ON_CHANGE_FORMS = "onChange";

export const ROUND = {
  min: 0,
  max: 2,
};

export const PERCENTAGE = {
  max: 100,
  min: 0,
};

export const MAX_LENGTH_INPUT = {
  currency: 20,
  number: 10,
};

export const MULTILINE = {
  min: 2,
};

export const KEY_PRESS = {
  enter: "Enter",
  escape: "Escape",
};

export const PAGINATION = {
  maxPerPage: 10,
  limited: 9,
  next: 1,
  minPerPage: 3,
  maxResultPerPage: 50,
};

export const COMPONENT = {
  fieldset: "fieldset",
  label: "label",
  span: "span",
  th: "th",
  div: "div",
  legend: "legend",
};

// DEPRECATED
export const INDEX = {
  zero: 0,
  one: 1,
  two: 2,
  three: 3,
  four: 4,
  five: 5,
  six: 6,
};

export const ALIGN_HEADER = {
  left: "flex-start",
  center: "center",
  right: "flex-end",
};

export const ALIGN_ITEMS = {
  center: "center",
  flex: "flex",
  flexStart: "flex-start",
  flexEnd: "flex-end",
  bottom: "bottom",
  left: "left",
  right: "right",
  top: "top",
  baseline: "baseline",
  inline: "inline",
  grid: "grid",
  topRight: "top-right",
  spaceBetween: "space-between",
};

export const DIRECTION = {
  column: "column",
  row: "row",
};

export const ADORMENT_POSITION = {
  end: "end",
  start: "start",
};

export const JUSTIFY = {
  spaceBetween: "space-between",
};

export const WIDTH = {
  text: 300,
  date: 200,
  select: 170,
  progressBar: 130,
  actions: 50,
  name: 250,
  state: 10,
  number: 50,
};

export const USD = "USD"; // FIXME: Temporal const; delete this when company's currency config will be ready

export const DATE_PARTS = {
  year: "year",
  years: "years",
  month: "month",
  months: "months",
  date: "date",
  day: "day",
  days: "days",
};

export const GRAMMATICAL_NUMBER = {
  singular: "singular",
  plural: "plural",
};

export const LISTENER = {
  scroll: "scroll",
};

export const SURVEY_PROCESS_TYPE = {
  performance: {
    key: "PerformanceProcess",
    value: "performance_processes",
    itemData: "performance_process",
    template: "PerformanceProcessTemplate",
    mandrillLabel: "performance",
    processType: "performance",
  },
  potential: {
    key: "PotentialProcess",
    value: "potential_processes",
    itemData: "potential_process",
    template: "PotentialProcessTemplate",
    mandrillLabel: "potential",
    processType: "potential",
  },
  goodLeader: {
    key: "GoodLeaderProcess",
    value: "good_leader_processes",
    itemData: "good_leader_process",
    template: "GoodLeaderProcessTemplate",
    mandrillLabel: "good-leader",
    processType: "good_leader",
  },
  engagement: {
    key: "EngagementProcess",
    value: "engagement_processes",
    itemData: "engagement_process",
    resultTypes: {
      satisfaction: "satisfaction_by_department",
      comments: "comments_by_department",
      nps: "nps_by_department",
    },
    template: "EngagementProcessTemplate",
    mandrillLabel: "engagement",
    processType: "engagement",
  },
  training: {
    key: "TrainingEvaluationProcess",
    value: "training_evaluation_processes",
    itemData: "training_evaluation_process",
  },
};

export const ACTIONS = {
  new: "new",
  edit: "edit",
  close: "close",
  preview: "preview",
  delete: "delete",
};

export const COMPANY_ID_PARAM = (id) => `?company_id=${id}`;
export const COLLABORATORS_PATH = "collaborators";
export const FEEDBACK_PATH = "feedbacks";
export const MOVEMENTS_PATH = "movements";
export const SURVEY_PROCESS_PATH = "survey_processes";
export const SURVEY_RESULTS_PATH = "survey_results";
export const GOOD_LEADER_PROCESSES_PATH = "good_leader_processes";
export const USER_ID_PARAM = (userId) => `user_id=${userId}`;
export const BOTTOM_RESULTS_PATH = "bottom_results";
export const TOP_RESULTS_PATH = "top_results";
export const SEND_PROCESS_PATH = "send_process";
export const SEND_PROCESS_REMINDER_PATH = "send_process_reminder";

export const DOWNLOAD_TYPE = {
  none: "none",
  excel: "excel",
  pdf: "pdf",
};

export const GOAL_RULES = {
  lessIsBetter: "LessIsBetter",
  moreIsBetter: "MoreIsBetter",
};

export const PATHS = {
  profile: "/profile",
  onboardingFollowUp: "/onboarding-follow-up",
  search: {
    collaborators: "?collaborator=",
  },
  performance: "/performance-review",
  engagement: "/engagement",
};

export const METHODS = {
  get: "get",
  post: "post",
  put: "put",
  delete: "delete",
};

export const LOCATION_PROPS = {
  search: "search",
  pathname: "pathname",
  state: "state",
  key: "key",
  hash: "",
};

export const INPUT_TYPE = {
  text: "text",
  select: "select",
  password: "password",
  file: "file",
  date: "date",
  number: "number",
  currency: "currency",
  email: "email",
  autocomplete: "autocomplete",
};

export const INPUT_RULES = {
  isValidEmail: {
    required: true,
    pattern: {
      value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
    },
  },
  isValidUserName: {
    required: true,
    pattern: {
      value: /^(?:[\d]+|[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4})$/i,
    },
  },
  required: {
    required: true,
  },
  // eslint-disable-next-line
  maxAndMinLength: (maxLength, required = true, minLength) => ({
    required,
    maxLength,
    minLength,
  }),
  passwordMatch: (password, t) => ({
    required: true,
    validate: (value) => value === password.current
      || t("formValidations:validations.password_dont_match"),
  }),
};

export const BUTTON_TYPE = {
  submit: "submit",
};

export const NO_AUTOCOMPLETE = "no";
export const AUTOCOMPLETE = {
  no: "no",
  off: "off",
};

export const FORM_MODE = {
  onChange: "onChange",
};

export const EXCEL_PROPS = {
  eachCell: "eachCell",
  style: {
    minWidth: 15,
    minLength: 0,
    maxLength: 5,
    border: {
      thin: "thin",
    },
    colors: {
      fgColorRequired: "FFFFFF00",
      bgColorRequired: "FF0000FF",
    },
  },
  type: {
    list: "list",
    pattern: "pattern",
  },
  errorStyle: {
    error: "error",
  },
  plusCell: "0000",
  pattern: {
    solid: "solid",
  },
  font: "Calibri",
  state: {
    hidden: "hidden",
  },
  position: {
    one: 1,
  },
};

export const COUNTRIES = {
  colombia: "Colombia",
};

export const IFRAME = {
  min: 500,
};

export const TARGET = {
  blank: "_blank",
  self: "_self",
};

export const FEEDBACK_TYPE = {
  feedbackSimple: "FeedbackSimple",
  feedbackSSC: "FeedbackStartStopContinue",
  feedbackSimpleSN: "feedback_simple",
  feedbackSSCSN: "feedback_start_stop_continue",
};

export const PARAMS_SERIALIZER_OPTIONS = {
  encode: false,
  arrayFormat: "brackets",
};

export const signInPath = "sign-in";
export const signInTitle = "sign_in";

export const MAX_POSITION_LENGTH = 30;

export const RADIX_PARAMETER = 10;

export const SCOPE = {
  row: "row",
};

export const FOLLOW_UP_STATUS = {
  complete: "complete",
  incomplete: "incomplete",
  pending: "pending",
  closed: "closed",
};

export const SIGN_IN_URLS = [
  `${CURRENT_DOMAIN}/${signInPath}`,
  `http://localhost:3000/${signInPath}`,
];

export const DEVELOPMENT_PLAN_INDEX_TABS = {
  inProgress: 0,
  overview: 1,
};

export const FILTER_TYPE = {
  general: "general",
};

export const GENERAL_ADMINISTRATOR = {
  units: "units",
};

export const TOAST_ERROR_DURATION = 5000;
export const COMMENTS = {
  maxLength: 255,
};

export const REL = "noopener noreferrer";

export const PLACEMENT_LENGTH = {
  min: 2,
  max: 30,
};

export const SCALE_COUNT = {
  min: 5,
};

export const CODE_EDITOR = {
  totalPercentage: "100%",
  height: "220px",
};

export const WRAP = {
  nowrap: "nowrap",
};

export const RESULT_TYPE = {
  submitted: "submitted",
  received: "received",
};

export const ACCEPTED_SECTION_ITEMS_FORMATS = ".pdf, .mp4";

export const MAX_FILE_WEIGHT = 5000000;

export const FILE_TYPE = {
  video: "video/mp4",
};

export const YOUTUBE_FORMAT = /^(?:https?:\/\/)?(?:m\.|www\.)?(?:youtu\.be\/|youtube\.com\/(?:embed\/|v\/|watch\?v=|watch\?.+&v=))((\w|-){11})(?:\S+)?$/;

export const VIMEO_FORMAT = /^(http:\/\/|https:\/\/)?(www\.|player\.)?vimeo.com\/(?:channels\/(?:\w+\/)?|groups\/(?:[^/]*)\/videos\/|album\/(?:\d+)\/video\/|video\/|)(\d+)(?:[a-zA-Z0-9_-]+)?/;

export const MP4_FORMAT = /\.mp4$/;

export const PDF_FORMAT = /\.pdf$/;

export const WEB_FORMAT = /^(http:\/\/|https:\/\/)?(www\.)?/;

export const FORMAT_TYPE = {
  mp4: {
    key: "mp4",
    value: "Video",
  },
  video: {
    key: "video",
    value: "Video",
  },
  pdf: {
    key: "pdf",
    value: "PDF",
  },
  website: {
    key: "website",
    value: "Website",
  },
};

export const URL_FORMAT = {
  mp4: [MP4_FORMAT],
  video: [YOUTUBE_FORMAT, VIMEO_FORMAT],
  pdf: [PDF_FORMAT],
  website: [WEB_FORMAT],
};

export const EMBED_VIDEO_URL = {
  youtube: "https://www.youtube.com/embed/",
  vimeo: "https://player.vimeo.com/video/",
};

export const YOUTUBE_VIDEO_ID_LENGTH = 11;

export const EMBED_VIDEO_BY_FORMATS = [
  {
    format: YOUTUBE_FORMAT,
    result: (match) => isEqual(match[INDEX.one].length, YOUTUBE_VIDEO_ID_LENGTH)
      && `${EMBED_VIDEO_URL.youtube}${match[INDEX.one]}`,
  },
  {
    format: VIMEO_FORMAT,
    result: (match) => `${EMBED_VIDEO_URL.vimeo}${match[INDEX.three]}`,
  },
];

export const US_CODE = "us";

export const COLOR_SCALE = [
  CRITIC_COLORS_RANGE.EXCELLENT,
  CRITIC_COLORS_RANGE.NORMAL,
  CRITIC_COLORS_RANGE.MEDIUM,
  CRITIC_COLORS_RANGE.LOW,
  CRITIC_COLORS_RANGE.CRITIC,
];

export const EVENT_STATE_KEYS = {
  incomplete: "incomplete",
  doesNotApply: "does_not_apply",
  complete: "complete",
  future: "future",
};

export const DOUGHNUT_SIZE = {
  small: 44,
  medium: 65,
  normal: 80,
  big: 85,
};

export const MEDIA_QUERY = {
  tablet: "768",
};

export const COMPANY_STATES = {
  active: "active",
  inactive: "inactive",
  hidden: "hidden",
};

export const TRUE = "true";

export const ACCEPTED_FILE_FORMATS = ".pdf, .jpg, .jpeg, .png";

export const ATTRITION_AXES_MAX_FOR_RECOMMENDATION = 0.70;

export const DATE_FORMATS = {
  es: {
    slash: "DD/MM/YYYY",
    slashShort: "DD/MM/YY",
    dash: "DD-MM-YYYY",
    slashTime: "DD/MM/YYYY hh:mm:ss",
    timeline: "yyyy-MM-DD",
    slashFullTime: "DD/MM/YYYY HH:mm:ss",
  },
  en: {
    slash: "MM/DD/YYYY",
    slashShort: "MM/DD/YY",
    dash: "MM-DD-YYYY",
    slashTime: "MM/DD/YYYY hh:mm:ss",
    timeline: "MM-DD-yyyy",
    slashFullTime: "MM/DD/YYYY HH:mm:ss",
  },
  pt: {
    slash: "DD/MM/YYYY",
    slashShort: "DD/MM/YY",
    dash: "DD-MM-YYYY",
    slashTime: "DD/MM/YYYY hh:mm:ss",
    timeline: "yyyy-MM-DD",
    slashFullTime: "DD/MM/YYYY HH:mm:ss",
  },
};

export const SCALE_ORDER = {
  min: 30,
};

export const ORIENTATION = {
  horizontal: "horizontal",
  vertical: "vertical",
};

export const DEFAULT_SORTS = {
  score: "score desc",
  performanceScore: "performance_score desc",
};

export const HTTP = "http";

export const SINGLE_PROFILE_PATHS = {
  collaborator: {
    route: "profile",
    parameter: "collaborator",
  },
  candidate: {
    route: "onboarding_profile",
    parameter: "candidate",
  },
};

export const MODULES = {
  profile: "profile",
  candidate: "candidate",
  home: "home",
  calendar: "calendar",
  organizationChart: "org-chart",
  acknowledgments: "acknowledgments",
  goals: "goals",
  cover: "cover",
  keyPositionsLast: "key_positions", // FIXME: remove
  developmentPlan: "development-plan",
  keyPositions: "key-positions",
};

export const GRID_ITEMS_TYPES = {
  cardProfile: "cardProfile",
};

export const MODULE_ACTIONS = {
  view: "view",
  create: "create",
  manage: "manage",
  edit: "edit",
  delete: "delete",
  update: "update",
};

export const TRANSITIONS_DURATION = {
  zoom: 800,
};

export const TRACKING_TAGS = {
  userId: "userId",
  employeeId: "employeeId",
  rol: "rol",
  language: "language",
  company: "company",
  email: "email",
  id: "id",
  // Goals
  generalGoals: "general-goals",
  createGoal: "create-goal",
  updateGoal: "update-goal",
  deleteGoal: "delete-goal",
  viewTeamGoals: "view-team-goals",
  downloadGoal: "download-goal",
  // Cover
  deletePeer: "delete-peer",
  // Acknowledgment
  acknowledgmentHomeClick: "acknowledgment-home-click",
  acknowledgmentHomeCreate: "acknowledgment-home-create",
  // Potential
  potentialTipsProfile: "potential-tips-profile", // TODO add
  // LeadershipSkills
  leadershipSkills: "leadership-skills",
  // Download
  downloadDevelopmentPlan: "download-development-plan",
  downloadNewHires: "download-new-hires",
  downloadParticipationPerformance: "download-participation-performance",
  downloadCriticalAreasMapPDFPerformance: "download-critical-areas-map-pdf-performance",
  downloadCriticalAreasMapExcelPerformance: "download-critical-areas-map-excel-performance",
  downloadPerformanceResults: "download-performance-results",
  downloadParticipationPotential: "download-participation-potential",
  downloadPotentialResults: "download-potential-results",
  downloadCriticalAreasMapPDFLeadershipSkills: "download-critical-areas-map-pdf-leadership-skills",
  downloadCriticalAreasMapExcelLeadershipSkills: "download-critical-areas-map-excel-leadership-skills",
  downloadLeadershipSkillsResults: "download-leadership-skills-results",
  downloadLeadershipResults: "download-leadership-results",
  downloadLAttritionRisk: "download-attrition-risk",
  downloadNineboxResults: "download-ninebox-results",
  downloadOnboarding: "download-onboarding",
  downloadEmployees: "download-employees",
  downloadAllMovements: "download-all-movements",
  downloadAllFeedbacksAndAcknowledgements: "download-all-feedbacks-and-acknowledgement",
  downloadTimeOff: "download-time-off",
  // Bulk upload
  bulkEmployees: "bulk-employees",
};

export const ZOOM = {
  defaultOrgChart: 0.7,
};

export const ICON_TYPES = {
  url: "url",
  icon: "icon",
};

export const CATEGORY_PROCESS = {
  high: "high",
  medium: "medium",
  low: "low",
};

import { useEffect, useCallback, useState } from "react";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { useQuery } from "react-query";
import moment from "moment";
import useComplexState from "hooks/utils/useComplexState";
import { OBJECT_KEYS, SURVEY_PROCESS_TYPE, STATE } from "common/constants";
import { isEmpty } from "common/helpers";
import {
  MESSAGE_TYPES,
  toast,
  handleMessages,
  HTTP_STATUS_RESPONSE,
} from "components/Toast/functions";
import {
  getList as getSurveyProcesses,
  getTemplatesList,
  resetState as resetStateSurveyProcesses,
  createOrUpdate as createOrUpdateSurveyProcess,
  resetState,
  resetStateOne,
  resetStateProcess,
  getTemplate,
  getDraftSurveys,
  createOrUpdateAsync,
  getProcessByIdAsync,
} from "redux/actions/surveyProcessesActions";
import { getList as getAudiences } from "redux/actions/audience/audienceActions";
import { getList as getResultScales, updateSurveyResult, restoreSurveyResult } from "redux/actions/resultScalesActions";
import {
  buildSurveyProcess,
  processDefaults,
  resetFormDataAndEvaluations,
  getProcessTypes,
  formatResultScaleData,
  getDefaultResultScale,
  setLocalStorageValues,
  formatAudienceTag,
} from "../functions";

export const usePlanningFormHook = (externalHooks) => {
  const { dispatch, t } = externalHooks;
  const history = useHistory();
  const location = useLocation();

  const surveyProcessQuery = {
    q: {
      follow_up_process_id_not_null: false,
      state_not_eq: "drafted",
    },
  };

  const {
    successProcess,
    loadingProcess,
    errorList,
    errorProcess,
    errorOne: errorOneResultProcess,
    one: oneResultProcess,
    loadingOne: isLoadingOneResultProcess,
    loadingList,
    errorTemplates: errorTemplatesList,
  } = useSelector(({ surveysReducer }) => surveysReducer);

  const {
    list: audiencesList,
    isLoadingList: isLoadingAudienceList,
  } = useSelector(({ audienceReducer }) => audienceReducer);

  const {
    list: resultScaleList,
    isLoadingList: isLoadingScaleList,
  } = useSelector(({ resultScalesReducer }) => resultScalesReducer);

  const [formStates, setFormStates] = useComplexState({
    processType: null,
    processId: null,
    processState: null,
    modal: false,
    audienceSelected: undefined,
    resetAudience: null,
    templateSelected: null,
    evaluations: null,
    resultScaleSelected: null,
    surveyResultDialog: false,
    restoreSurveyResultDialog: false,
  });

  const [formValues, setFormValues] = useState(processDefaults);
  const [defaultValues, setFormDefaultValues] = useState(null);
  const [dialogUpdate, setDialogUpdate] = useState(false);

  const [participationModal, setParticipationModal] = useState(null);

  const {
    data: templatesList,
    isLoading: isLoadingTemplatesList,
    refetch: updateTemplateList,
    isFetching: isFetchingTemplatesList,
  } = useQuery(
    "templatesList",
    () => dispatch(getTemplatesList()),

    {
      staleTime: Infinity,
    },
  );

  const {
    data: templateData,
    isLoading: isLoadingTemplate,
    refetch: refetchTemplateData,
    isFetching: isFetchingTemplate,
  } = useQuery(
    ["templateData", formStates.templateSelected?.id],
    () => dispatch(getTemplate(formStates.templateSelected?.id)),
    {
      staleTime: Infinity,
      enabled: false, // property is used to determine if the query should be executed or not
      retry: false,
    },
  );

  const {
    data: draftSurveys, isLoading: isLoadingDraftSurveys,
    refetch: refetchDraftSurveys,
  } = useQuery("draftSurveys", () => dispatch(getDraftSurveys()), {
    staleTime: Infinity,
  });

  // get all processes
  useEffect(() => {
    dispatch(getAudiences());
    dispatch(getSurveyProcesses(false, surveyProcessQuery));
    dispatch(getResultScales());
    // eslint-disable-next-line
  }, [dispatch]);

  const methods = useForm({
    defaultValues: processDefaults,
  });

  const {
    control, handleSubmit, reset, watch,
  } = methods;
  const watchTemplate = watch(OBJECT_KEYS.surveyProcessTemplateId);
  const watchResultScale = watch(OBJECT_KEYS.resultScaleId);
  const formValuesWatch = watch();

  const getInitialFormValues = useCallback(() => {
    const savedValues = localStorage.getItem("formValues");
    const templateSelected = localStorage.getItem("templateSelected");
    const templateSelectedId = JSON.parse(templateSelected)?.id;

    const newFormValues = {
      ...JSON.parse(savedValues),
      survey_process_template_id: templateSelectedId,
    };
    if (savedValues) {
      if (location.state?.from === "/planning/templates") {
        const processTypes = getProcessTypes(t);
        const processTypeValues = processTypes.find(
          (process) => SURVEY_PROCESS_TYPE.performance.value === process.value,
        );
        setFormStates({
          processType: processTypeValues,
        });

        if (templateSelectedId) {
          updateTemplateList().then((response) => {
            const newTemplatesList = response.data;
            const templateInfo = newTemplatesList.find((e) => e.id === templateSelectedId);
            setFormStates({
              templateSelected: templateInfo,
            });
            setFormValues(newFormValues);
            reset(newFormValues);
            if (templateInfo !== undefined) {
              localStorage.removeItem("formValues");
              localStorage.removeItem("templateSelected");
            }
          });
        }
      } else {
        localStorage.removeItem("formValues");
        localStorage.removeItem("templateSelected");
      }
    }
  });

  useEffect(() => {
    if (templatesList && templatesList.length > 0) {
      getInitialFormValues();
    }
  }, [templatesList]);

  const validateDates = (startingDate, endDate) => {
    if (!startingDate || !endDate) {
      toast(MESSAGE_TYPES.error, { title: t("goals:form.title"), message: t("goals:form.required_dates") });
      return false;
    }
    if (new Date(endDate) <= new Date(startingDate)) {
      toast(MESSAGE_TYPES.error, { title: t("goals:form.title"), message: t("goals:form.validation_dates") });
      return false;
    }
    return true;
  };

  const sendData = (toProgram) => {
    const processData = {
      ...formValues,
      ...formValuesWatch,
    };
    processData.type = formStates.processType.value;
    const data = buildSurveyProcess(
      processData,
      formStates.audienceSelected,
      formStates.processId,
      formStates.processState,
      toProgram,
    );
    const { start_date: startDate, end_date: endDate } = data;
    if (!validateDates(startDate, endDate)) {
      return;
    }
    let transition = null;
    if (formStates.processState === STATE.finished && endDate >= moment()) {
      transition = {
        state_transition: STATE.launch,
      };
    } else if (formStates.processState === STATE.running && endDate < moment()) {
      transition = {
        state_transition: STATE.finish,
      };
    }
    dispatch(createOrUpdateSurveyProcess(data, data.type, formStates.processId, t, transition));
  };

  const onSubmit = () => {
    sendData(true);
  };

  const changeProcess = (process) => {
    setLocalStorageValues();
    const defaultResultScale = getDefaultResultScale(resultScaleList, process);
    setFormStates({
      processType: process,
      processId: null,
      audienceSelected: undefined,
      resetAudience: null,
      templateSelected: null,
      evaluations: null,
      resultScaleSelected: defaultResultScale,
    });
    const defaultProcess = processDefaults;
    defaultProcess.result_scale_id = defaultResultScale?.id;
    defaultProcess.name_es = process.labelValue.es;
    defaultProcess.name_en = process.labelValue.en;
    defaultProcess.name_pt = process.labelValue.pt;
    setFormValues(defaultProcess);
  };

  const saveDraft = () => {
    sendData(false);
  };

  const saveDraftAndOpenModal = async () => {
    const processData = {
      ...formValues,
      ...formValuesWatch,
    };
    processData.type = formStates.processType.value;
    const data = buildSurveyProcess(
      processData,
      formStates.audienceSelected,
      formStates.processId,
      formStates.processState,
      false,
    );
    const { start_date: startDate, end_date: endDate } = data;
    if (!validateDates(startDate, endDate)) {
      return;
    }
    let transition = null;
    if (formStates.processState === STATE.finished && endDate >= moment()) {
      transition = {
        state_transition: STATE.launch,
      };
    } else if (formStates.processState === STATE.running && endDate < moment()) {
      transition = {
        state_transition: STATE.finish,
      };
    }
    const process = await dispatch(
      createOrUpdateAsync(data, data.type, formStates.processId, t, transition),
    );
    const newData = Object.values(process)[0];
    if (process === true) {
      setTimeout(async () => {
        const surveyProcess = await dispatch(getProcessByIdAsync(formValues.id));
        const surveyProcessUpdated = Object.values(surveyProcess)[0];
        setFormValues({
          ...formValues,
          participation_schema: surveyProcessUpdated.participation_schema,
          audience_id: surveyProcessUpdated.audience_id,
        });
        setFormDefaultValues(surveyProcessUpdated);
        setParticipationModal(surveyProcessUpdated);
        if (formStates.processState === STATE.drafted) {
          refetchDraftSurveys();
        }
      }, 3000);
    } else if (newData?.id) {
      setFormStates({
        processId: newData.id,
        processState: newData.state,
      });
      setFormValues({
        ...formValues,
        id: newData.id,
        state_transition: newData.state,
        state: newData.state,
        participation_schema: newData.participation_schema,
      });
      refetchDraftSurveys();
      setParticipationModal(newData);
    }
  };

  const saveResultScale = async () => {
    if (formValuesWatch?.result_scale_indices_attributes) {
      const resultScaleId = formValuesWatch.result_scale_id;
      const formattedResultScale = formatResultScaleData(
        formValuesWatch.result_scale_indices_attributes,
      );
      const resultScaleResponse = await dispatch(
        updateSurveyResult(resultScaleId, formattedResultScale),
      );
      setFormStates({
        surveyResultDialog: false,
      });
      if (resultScaleResponse) {
        const index = resultScaleList.findIndex(
          (resultScale) => resultScale.id === resultScaleResponse.id,
        );
        if (index !== -1) {
          resultScaleList.splice(index, 1);
        }
        resultScaleList.push(resultScaleResponse);
      }
    }
  };

  const restoreResultScale = async () => {
    const resultScaleId = formValuesWatch.result_scale_id;
    const resultScaleResponse = await dispatch(
      restoreSurveyResult(resultScaleId),
    );
    if (resultScaleResponse) {
      setFormStates({
        resultScaleSelected: null,
      });
      if (formValuesWatch?.result_scale_indices_attributes) {
        delete formValuesWatch.result_scale_indices_attributes;
      }
      if (formValues?.result_scale_indices_attributes) {
        delete formValues.result_scale_indices_attributes;
      }
      const index = resultScaleList.findIndex(
        (resultScale) => resultScale.id === resultScaleResponse.id,
      );
      if (index !== -1) {
        resultScaleList.splice(index, 1);
      }
      resultScaleList.push(resultScaleResponse);
      setFormStates({
        resultScaleSelected: resultScaleResponse,
        restoreSurveyResultDialog: false,
      });
    }
  };

  // // reset form with process data to edit
  const resetForm = useCallback(() => {
    const { editData, evaluations } = resetFormDataAndEvaluations(formStates.processType?.value, oneResultProcess, t);
    let template = null;
    if (editData?.survey_process_template_id) {
      template = templatesList.find((e) => e.id === editData.survey_process_template_id);
    }
    if (editData) reset(editData);
    setFormValues(editData);
    setFormDefaultValues(editData);
    setFormStates({ processState: editData.state, evaluations, templateSelected: template });

    if (editData.audience_id === null) {
      setFormStates({
        resetAudience: null,
      });
    } else if (!isEmpty(audiencesList) && audiencesList !== null) {
      const audience = audiencesList.find((e) => e.id === editData.audience_id);
      if (audience) {
        setFormStates({
          resetAudience: formatAudienceTag(audience),
        });
      }
    }
  // eslint-disable-next-line
  }, [reset, t, formStates.processType, oneResultProcess, audiencesList]);

  const handleAddModal = () => {
    setFormStates({
      modal: !formStates.modal,
    });
  };

  useEffect(() => {
    if (formStates.templateSelected?.id) {
      refetchTemplateData();
    }
    // eslint-disable-next-line
  }, [formStates.templateSelected]);

  // reset
  const resetData = useCallback(() => {
    setFormStates({
      processType: null,
      processId: null,
      templateSelected: null,
      evaluations: null,
      resultScaleSelected: null,
    });
    // eslint-disable-next-line
    }, []);

  useEffect(() => {
    if (watchTemplate) {
      const template = templatesList.find((e) => e.id === watchTemplate);
      if (template) {
        setFormStates({
          templateSelected: template,
        });
        setFormValues({
          ...formValues,
          survey_process_template_id: template.id,
          state_transition: template.state,
        });
      }
    }
  }, [watchTemplate]);

  useEffect(() => {
    if (watchResultScale) {
      const resultScale = resultScaleList?.find((e) => e.id === watchResultScale);
      if (resultScale) {
        setFormStates({
          resultScaleSelected: resultScale,
        });
      }
    }
  }, [watchResultScale]);

  useEffect(() => {
    if (oneResultProcess && !isLoadingOneResultProcess && formStates.processType) {
      resetForm();
    }
  }, [oneResultProcess, isLoadingOneResultProcess, resetForm, formStates.processType]);

  // error handling
  useEffect(() => {
    const errors = errorList || errorProcess || errorOneResultProcess;
    if (errors) {
      if (errorList) dispatch(resetState());
      if (errorProcess) dispatch(resetStateProcess());
      if (errorOneResultProcess) dispatch(resetStateOne());
    }
  }, [errorList, errorProcess, errorOneResultProcess, t, dispatch]);

  const resetProcess = () => {
    dispatch(resetStateOne());
    dispatch(resetStateProcess());
    resetData();
    setFormValues(processDefaults);
  };

  // handling of completed processes
  const viewMessageAndResetData = useCallback(() => {
    resetProcess();
    toast(MESSAGE_TYPES.success, handleMessages(MESSAGE_TYPES.success, HTTP_STATUS_RESPONSE.ok, t));
    dispatch(resetStateSurveyProcesses());
    dispatch(getSurveyProcesses(false, surveyProcessQuery));
    refetchDraftSurveys();
  }, [t, dispatch, resetData]);

  useEffect(() => {
    if (successProcess) {
      viewMessageAndResetData();
    }
    // eslint-disable-next-line
  }, [successProcess]);

  const handleCustomizeProcess = () => {
    localStorage.setItem("formValues", JSON.stringify(formValues));
    localStorage.setItem("templateSelected", JSON.stringify(formStates.templateSelected));
    const viewTemplate = `?${formStates.processType.value}=${formValues.survey_process_template_id}`;
    history.push(`/planning/templates${viewTemplate}`);
  };

  return {
    surveysReducerStates: {
      successProcess,
      loadingProcess,
      errorList,
      errorProcess,
      errorOneResultProcess,
      oneResultProcess,
      loadingList,
    },
    isLoadingForm: isLoadingOneResultProcess,
    isLoadingAudienceList,
    audienceReducerStates: {
      audiencesList,
    },
    templatesList,
    isLoadingTemplatesList,
    isFetchingTemplatesList,
    errorTemplatesList,
    control,
    formStates,
    setFormStates,
    formValues,
    setFormValues,
    methods,
    handleSubmit,
    reset,
    onSubmit,
    saveDraft,
    changeProcess,
    resetData,
    resetForm,
    handleAddModal,
    viewMessageAndResetData,
    handleCustomizeProcess,
    templateData,
    isLoadingTemplate,
    isFetchingTemplate,
    draftSurveys,
    isLoadingDraftSurveys,
    resetProcess,
    resultScaleList,
    isLoadingScaleList,
    saveResultScale,
    restoreResultScale,
    saveDraftAndOpenModal,
    participationModal,
    setParticipationModal,
    defaultValues,
    dialogUpdate,
    setDialogUpdate,
  };
};

import styled from "styled-components";
import Chip from "@mui/material/Chip";
import palette from "theme/palette";

export const StyledChip = styled(Chip)`
  &.MuiChip-root {
    background-color: ${(props) => props.background};
    color: ${(props) => props.textcolor};
    margin-bottom: 5px;
  }
  .MuiChip-deleteIcon {
    color: ${(props) => props.textcolor};
  }
`;

export const StyledDeleteChip = styled(Chip)`
  font-size: 10px;
  color: ${palette.background.valid};
  border-color: ${palette.background.valid};
  cursor: pointer;
  &.MuiChip-root {
    margin: ${(props) => props.mc || "1px"};
    height: 18px;
    span {
      width: ${(props) => props.width || "60px"};
      text-align: left;
    }
    .MuiChip-deleteIcon {
      color: ${palette.background.valid};
    }
  }
`;

import { useState } from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Avatar from "@mui/material/Avatar";
import NoDataMessage from "components/NoDataMessage";
import Banner from "components/Banner";
import { isSimpleTypeEvaluation } from "views/SurveyEvaluationsPreview/functions";
import {
  StyledContainer,
  StyledContent,
} from "views/SurveyEvaluationsPreview/styles";
import { EVALUATION_TYPE } from "common/constants/surveyProcess";
import { getAvatar } from "common/utils";
import ResponsiveQuestions from "../ResponsiveQuestions";
import {
  StyledEvaluationTitle, StyledImage, StyledTitle, StyledMember, StyledEvaluated,
} from "../../styles";

const EvaluationContent = (props) => {
  const {
    evaluation,
    evaluationScales,
    control,
    handleModalSubmit,
    isDisabled,
    handleSendDataByQuestion,
    evaluationResult,
    isMobile,
    processId,
    processData,
    handleSubmit,
    slideAnswers,
    setSlideAnswers,
    loadingProcess,
    evaluationFormStates,
    t,
    handleToCoverOrHomeProcess,
    handleNextRef,
  } = props;

  const [teamAnswers, setTeamAnswers] = useState({});
  const [isConfirmPage, setIsConfirmPage] = useState(false);

  const {
    oneProcess: surveyProcess,
  } = useSelector(({ surveysReducer }) => surveysReducer);

  const handleConfirmPage = (state) => {
    setIsConfirmPage(state);
  };

  // Evaluated information
  const getEvaluated = () => {
    if (!isSimpleTypeEvaluation(evaluation.type) && evaluationResult && !isConfirmPage) {
      return (
        <StyledContainer>
          <StyledContent>
            <Grid container>
              <Grid item>
                {!isMobile
                  && (
                    <StyledEvaluated>
                      { t("surveys:form.fields.evaluated") }
                    </StyledEvaluated>
                  )}
                {/* FIXME: name = full_name */}
                <StyledMember>
                  <Avatar
                    alt={ evaluationResult.evaluation_details.evaluated.name }
                    src={ getAvatar(evaluationResult.evaluation_details.evaluated) }
                  />
                  <Typography>{evaluationResult.evaluation_details.evaluated.name}</Typography>
                </StyledMember>
              </Grid>
            </Grid>
          </StyledContent>
        </StyledContainer>
      );
    }
    return null;
  };

  // Builds the entire form based on its sections and questions
  const getEvaluationContent = () => {
    let content = <NoDataMessage />;
    if (evaluation?.evaluation_sections.length > 0 && processData) {
      content = (
        <form
          id={ `evaluation-form-${processData.label}` }
          onSubmit={ handleSubmit(handleModalSubmit) }
        >
          { !isMobile && !processId && getEvaluated() }
          <ResponsiveQuestions
            evaluation={ evaluation }
            evaluationScales={ evaluationScales }
            control={ control }
            onSubmit={ handleModalSubmit }
            mobileAnswers={ slideAnswers }
            setMobileAnswers={ setSlideAnswers }
            teamAnswers={ teamAnswers }
            setTeamAnswers={ setTeamAnswers }
            isLoading={ loadingProcess }
            members={ evaluationFormStates.teamMembers }
            isDisabled={ isDisabled }
            handleSendDataByQuestion={ handleSendDataByQuestion }
            evaluationResult={ evaluationResult }
            handleToCoverOrHomeProcess={ handleToCoverOrHomeProcess }
            handleConfirmPage={ handleConfirmPage }
            handleNextRef={ handleNextRef }
          />
        </form>
      );
    }
    return content;
  };

  return (
    <>
      {isMobile ? (
        <StyledEvaluationTitle>
          <StyledTitle>
            <StyledImage src={ processData.icon } alt={ t(`common:common.evaluations.${evaluation?.type}.title`) } />
            { surveyProcess && surveyProcess[processData?.itemData] ? surveyProcess[processData?.itemData].name : "" }
            <br />
            { evaluation?.type === EVALUATION_TYPE.OVERALL ? "" : t(`common:common.evaluations.${evaluation?.type}.title`) }
          </StyledTitle>
          { !processId && !isConfirmPage && getEvaluated() }
        </StyledEvaluationTitle>
      ) : (
        <Banner
          title={ surveyProcess && surveyProcess[processData?.itemData] ? surveyProcess[processData?.itemData].name : "" }
          text={ evaluation?.type === EVALUATION_TYPE.OVERALL ? "" : t(`common:common.evaluations.${evaluation?.type}.title`) }
          icon={ processData.icon }
        />
      )}
      { getEvaluationContent() }
    </>
  );
};

EvaluationContent.propTypes = {
  evaluation: PropTypes.object.isRequired,
  evaluationScales: PropTypes.object.isRequired,
  control: PropTypes.object.isRequired,
  handleModalSubmit: PropTypes.func.isRequired,
  isDisabled: PropTypes.bool.isRequired,
  handleSendDataByQuestion: PropTypes.func.isRequired,
  evaluationResult: PropTypes.object.isRequired,
  isMobile: PropTypes.bool.isRequired,
  processId: PropTypes.number.isRequired,
  processData: PropTypes.object.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  slideAnswers: PropTypes.array.isRequired,
  setSlideAnswers: PropTypes.func.isRequired,
  loadingProcess: PropTypes.bool.isRequired,
  evaluationFormStates: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired,
  handleToCoverOrHomeProcess: PropTypes.func.isRequired,
  handleNextRef: PropTypes.object.isRequired,
};

export default EvaluationContent;

import React from "react";
import { LOCAL_STORAGE_NAMES } from "common/constants";
import { setInLocalStorageAsync, getItemFromLocalStorage } from "common/utils";

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  componentDidCatch(/* error, info */) {
    this.setState({ hasError: true });
  }

  render() {
    const { hasError /* error, info */ } = this.state;

    if (hasError) {
      if (process.env.REACT_APP_ENV === "test") {
        return this.props.children;
      }

      // Set logoutError to localStorage and remove localStorage data
      setInLocalStorageAsync(
        LOCAL_STORAGE_NAMES.logOutError,
        true,
      );
      const hasLogoutError = getItemFromLocalStorage(LOCAL_STORAGE_NAMES.logOutError);
      if (hasLogoutError) {
        window.location.replace("/");
      }
    }
    return this.props.children;
  }
}

export default ErrorBoundary;

import PropTypes from "prop-types";
import Typography from "@mui/material/Typography";
import Slider from "@mui/material/Slider";
import Avatar from "@mui/material/Avatar";
import Radio from "@mui/material/Radio";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import InputText from "components/InputText";
import {
  VARIANT,
  ALIGN_ITEMS,
  ORIENTATION,
} from "common/constants";
import { isEqual } from "common/helpers";
import { validateShowLabel, getValue, getLabel } from "components/MultipleAnswers/functions";
import { StyledSliderContent } from "styledComponents/SliderQuestion";
import { getAvatar } from "common/utils";
import {
  StyledAvatar, StyledScale,
  StyledTableCell,
} from "./styles";

const DesktopScale = (props) => {
  const {
    id,
    isShortAnswer,
    isNumberScale,
    scale,
    members,
    teamAnswers,
    marks,
    language,
    handleChange,
    isDisabled,
  } = props;

  const thumbComponent = (props) => (isNumberScale ? (
    <span { ...props }>
      {marks[props["aria-valuenow"]]?.firstLabel}
    </span>
  ) : (<span { ...props } />));

  const questions = (member) => scale?.indices?.map((item, index) => {
    const memberId = member.id;
    return (
      <StyledTableCell
        key={ `cell-${index}` }
        total={ scale?.indices.length }
        isLastOne={ isEqual(index, scale.indices.length - 1) }
        align={ ALIGN_ITEMS.center }
        hasBorderRight
        hasBorderTop
      >
        <Radio
          checked={ isEqual(item[`answer_${language}`], teamAnswers[memberId] && teamAnswers[memberId][id]) }
          onChange={ (event) => handleChange(id, event.target.value, memberId) }
          value={ item[`answer_${language}`] }
          disabled={ isDisabled }
          isRequired
        />
      </StyledTableCell>
    );
  });

  // Handles the onMouseUp event of the slider
  // (used when the slider is released or the pressing is completed)
  const handleSliderMouseUp = (memberId, value) => {
    const validValue = value === undefined ? 0 : value;
    const newValue = marks[validValue].firstLabel;
    handleChange(id, newValue, memberId);
  };

  const radioOrSliderRender = (member) => {
    if (isShortAnswer) {
      const value = getValue(id, teamAnswers, marks, member.id);
      const label = getLabel(id, teamAnswers, marks, member.id);

      const isLastMark = value === marks.length - 1;

      return (
        <TableCell>
          <StyledSliderContent
            isShortAnswer={ isShortAnswer }
            value={ value }
          >
            <Slider
              className={ "multiple-answers" }
              orientation={ ORIENTATION.horizontal }
              valueLabelDisplay={ "auto" }
              valueLabelFormat={ (
                <div className={ isLastMark ? "last" : "" }>
                  {label}
                </div>
              ) }
              value={ value }
              step={ null }
              track={ false }
              marks={ marks }
              max={ marks ? marks.length - 1 : 0 }
              onChange={ (event, newValue) => handleChange(
                id, marks[newValue].firstLabel, member.id, false,
              ) }
              onMouseUp={ () => handleSliderMouseUp(member.id, value) }
              ThumbComponent={ thumbComponent }
              disabled={ isDisabled }
              data-member-id={ member.id }
            />
          </StyledSliderContent>
        </TableCell>
      );
    }
    return questions(member);
  };

  return (
    <Table>
      <TableBody>
        {/* TODO: Add sticky position */}
        <TableRow>
          {scale && <StyledTableCell>{""}</StyledTableCell>}
          {isShortAnswer ? (
            <TableCell>
              <StyledScale>
                {marks.map((item, index) => (
                  <p key={ item }>
                    {validateShowLabel(index, scale?.indices) ? item.firstLabel : ""}
                  </p>
                ))}
              </StyledScale>
            </TableCell>
          ) : scale?.indices?.map((item, index) => (
            <StyledTableCell
              key={ item }
              total={ scale?.indices.length }
              isLastOne={ isEqual(index, scale.indices.length - 1) }
              isHeader
              hasBorderRight
            >
              {item[`answer_${language}`]}
            </StyledTableCell>
          ))}
        </TableRow>
        {members.map((member) => (
          <TableRow key={ member }>
            <StyledTableCell hasBorderTop={ !!scale && !isShortAnswer }>
              <StyledAvatar>
                <Avatar
                  alt={ member.full_name }
                  src={ getAvatar(member) }
                  key={ member.id }
                />
                <Typography variant={ VARIANT.h6 }>{ member?.full_name }</Typography>
              </StyledAvatar>
            </StyledTableCell>
            {scale ? radioOrSliderRender(member) : (
              <TableCell>
                <InputText
                  id={ id }
                  label={ "" }
                  name={ id }
                  variant={ VARIANT.outlined }
                  onChange={ (prop, event) => handleChange(id, event.target.value, member.id, false) }
                  value={ teamAnswers[member.id] && id in teamAnswers[member.id] ? teamAnswers[member.id][id] || "" : "" }
                  multiline={ 4 }
                  onBlur={ () => handleChange(id, teamAnswers[member.id] && id in teamAnswers[member.id] ? teamAnswers[member.id][id] || "" : "", member.id) }
                  disabled={ isDisabled }
                />
              </TableCell>
            )}
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
};

DesktopScale.propTypes = {
  id: PropTypes.string.isRequired,
  isShortAnswer: PropTypes.bool.isRequired,
  isNumberScale: PropTypes.bool.isRequired,
  scale: PropTypes.object.isRequired,
  members: PropTypes.array.isRequired,
  teamAnswers: PropTypes.object.isRequired,
  marks: PropTypes.array.isRequired,
  language: PropTypes.string.isRequired,
  handleChange: PropTypes.func.isRequired,
  isDisabled: PropTypes.bool.isRequired,
};

export default DesktopScale;

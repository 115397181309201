import Question from "components/Question";
import { EVALUATION_TYPE } from "common/constants/surveyProcess";
import { OBJECT_KEYS } from "common/constants";
import { isEqual, isNull, isUndefined } from "common/helpers";
import { getScaleIndicesOrder } from "common/utils";
import MultipleAnswers from "components/MultipleAnswers";

export const getStepsBySection = (
  t,
  section,
  control,
  evaluationScales,
  isMobile,
  mobileAnswers,
  handleSlideAnswers,
  teamAnswers,
  handleTeamAnswers,
  language,
  members,
  isDisabled,
  handleMemberAnswers,
  evaluationResult,
  handleAnswers,
  hasGeneralComments,
) => {
  const stepsBySection = [];
  section.evaluation_questions.forEach((question) => {
    const questionScaleId = question.evaluation_scale_id;
    const scale = evaluationScales
      ?.find((evalScale) => isEqual(evalScale.id, questionScaleId));
    if (scale && isUndefined(question.scale)) {
      question.scale = scale;
      question.scale.indices = getScaleIndicesOrder(scale.indices, !isNull(members));
    }
    let defaultValue = null;
    if (evaluationResult) {
      const answers = evaluationResult.complete_answers?.flatMap((item) => item.answers);
      const answerByQuestion = answers?.find(
        (answer) => answer.evaluation_question_id === question.id,
      );
      const answerByScale = answerByQuestion ? question.scale?.indices.find(
        (scaleIndex) => scaleIndex.id === answerByQuestion.evaluation_scale_index_id,
      ) : null;
      if (answerByScale) {
        defaultValue = answerByScale[`answer_${language}`];
      } else if (answerByQuestion?.answer_value) {
        // open question
        defaultValue = answerByQuestion.answer_value;
      }
    }
    if (!(question.id.toString() in mobileAnswers)) {
      handleSlideAnswers(question.id.toString(), defaultValue);
    }
    if (!isNull(members) && !isMobile) {
      members.forEach((member) => {
        let defaultMemberValue = null;
        const answers = member.evaluationResult.complete_answers?.flatMap((item) => item.answers);
        const generalComment = member.evaluationResult?.comment;
        const answerByQuestion = answers?.find(
          (answer) => answer.evaluation_question_id === question.id,
        );
        const answerByScale = question.scale && answerByQuestion ? question.scale.indices.find(
          (scaleIndex) => scaleIndex.id === answerByQuestion.evaluation_scale_index_id,
        ) : null;
        if (!teamAnswers[member.id] || !(question.id.toString() in teamAnswers[member.id])) {
          if (answerByScale) {
            defaultMemberValue = answerByScale[`answer_${language}`];
          } else if (answerByQuestion?.answer_value) {
            defaultMemberValue = answerByQuestion.answer_value;
          }
          handleTeamAnswers(
            question.id.toString(),
            defaultMemberValue,
            member.id,
          );
          if (hasGeneralComments && !teamAnswers[member.id]?.comment) {
            handleTeamAnswers(
              OBJECT_KEYS.comment,
              generalComment,
              member.id,
            );
          }
        }
      });
    }
    stepsBySection.push({
      title: section[`name_${language}`],
      isOpen: section.open,
      id: question.id,
      questionName: members ? question[`name_${language}`] : null,
      descriptionScale: members && scale ? scale[`description_${language}`] : null,
      detail: question.scale || section.open ? (members ? (
        <MultipleAnswers
          id={ question.id.toString() }
          scale={ question.scale }
          members={ members }
          teamAnswers={ teamAnswers }
          handleTeamAnswers={ handleMemberAnswers }
          isDisabled={ isDisabled }
        />
      ) : (
        <Question
          id={ question.id.toString() }
          title={ question[`name_${language}`] }
          key={ question.id }
          scale={ question.scale }
          control={ control }
          handleAnswers={ handleAnswers }
          isDisabled={ isDisabled }
          value={ mobileAnswers[question.id.toString()] !== null ? mobileAnswers[question.id.toString()] : defaultValue }
        />
      )) : "",
    });
  });
  if (section.has_comments) {
    stepsBySection.push({
      title: section[`name_${language}`],
      isOpen: section.open,
      questionName: members ? t("surveys:form.fields.general_data.comments") : null,
      detail: members ? (
        <MultipleAnswers
          id={ `comment_${section.id}` }
          members={ members }
          teamAnswers={ teamAnswers }
          handleTeamAnswers={ handleMemberAnswers }
          isDisabled={ isDisabled }
        />
      ) : (
        <Question
          id={ `comment_${section.id}` }
          title={ t("surveys:form.fields.general_data.comments") }
          control={ control }
          handleAnswers={ handleAnswers }
          value={ mobileAnswers[`comment_${section.id}`] }
          isDisabled={ isDisabled }
        />
      ),
    });
  }
  return stepsBySection;
};

export const getResultId = (link) => link?.split("evaluation_result")[1].split("=")[1];

export const isValidToViewFinalResults = (type) => {
  const types = [
    EVALUATION_TYPE.MANAGER,
    EVALUATION_TYPE.PAIRS,
    EVALUATION_TYPE.AUTOEVALUATION,
  ];
  return types.includes(type);
};

export const sortAllResponses = (answers, questionSteps, hasComments) => {
  // Map through all the answers
  const sortedAllResponses = Object.values(answers).map((answer) => {
    const sortedResponses = {};
    const steps = questionSteps[0]?.detail;

    // Sort the responses based on question steps
    steps.forEach((obj, index) => {
      const { id } = obj;
      if (id in answer) {
        sortedResponses[index] = answer[id];
      }
    });
    if (hasComments) {
      sortedResponses.comment = answer?.comment;
    }

    return sortedResponses;
  });

  return sortedAllResponses;
};


export const sortIndividualAnswers = (answers, questionSteps, hasComments, comment) => {
  const sortedIndividualAnswers = {};

  questionSteps.forEach((obj, index) => {
    const { id } = obj;
    if (id in answers) {
      sortedIndividualAnswers[index] = { [id]: answers[id] };
    }
  });
  if (hasComments) {
    questionSteps.comment = comment;
  }
  return sortedIndividualAnswers;
};

export const memberDataByDefault = {
  id: null,
  memberData: null,
  memberId: null,
};
